import React, { useEffect, memo } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import PureCounter from '@srexi/purecounterjs';
function About() {
    useEffect(() => {
        new PureCounter();
        AOS.init({
            duration: 1000,  // Animation duration in milliseconds
            once: true,      // Animation should happen only once
        });
    }, []);
    return (
        <>
            <section id="about" className="about section" data-aos="fade-up" data-aos-delay="100">
                <div className="container section-title">
                    <h2>About</h2>
                    <div>
                        {/* <span>Learn More</span> */}
                        <span className="description-title">About Us</span>
                    </div>
                </div>
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-12 col-md-6 col-sm-6 content">
                            <p>
                                Welcome to Re-boonding Family Salon, the best hair salon in Varanasi Dugrakund. Our expert stylists specialize in a wide range of services including hair cuts, hair coloring, hair stylist, beautician, gloamorous, bridal makeup, party makeup, groom makeup, mehndi keratin, smoothening treatment and more with affordable and reasonable price also get better discount. We’re here to help you look and feel your best.
                                Our salon is conveniently located in the heart of Varanasi and serves clients from nearby areas like SKON Temple and Durgakund jawahar nagar. We use only the best and top products to ensure your hair stays healthy and vibrant.
                            </p>

                        </div>
                        {/* <div className="col-lg-6"> */}
                            {/* <p>
                            Welcome to Re-boonding Family Salon, the best hair salon in Varanasi Dugrakund. Our expert stylists specialize in a wide range of services including hair cuts, hair coloring, hair stylist, beautician, gloamorous, bridal makeup, party makeup, groom makeup, mehndi keratin, smoothening treatment and more with affordable and reasonable price also get better discount. We’re here to help you look and feel your best.
                            </p> */}
                            {/* <a href="#" className="read-more">
                                <span>Read More</span><i className="bi bi-arrow-right"></i>
                            </a> */}
                        {/* </div> */}
                    </div>
                </div>
            </section>


        </>

    )
}

export default memo(About)