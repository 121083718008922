import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from '../Component/Footer'
import Header from '../Component/Header'
import { Link } from 'react-router-dom';
function SalonPage() {
  useEffect(() => {
    AOS.init({
      duration: 1000,  // Animation duration in milliseconds
      once: true,      // Animation should happen only once
    });
  }, []);
  return (
    <div>
      <Header />

      <div className="container text-center my-5" >
        {/* Logo Section */}
        <div className="my-4">
          <img
            src='assets/img/reboonding_logo.png' // Add your logo path here
            alt="Re-boonding Family Salon"
            style={{ maxWidth: '200px' }}
          />
          <h2 style={{ fontWeight: 'bold' }} className="mt-2">Re-boonding Family Salon</h2>
        </div>

        {/* Service Cards */}
        <div className="row mt-5" data-aos="fade-up" data-aos-delay="100">
          {/* Existing Service Cards */}
          <Link className="col-md-4 mb-4" to={'/enquiry'}>
            <div className="card shadow-sm">
              <div className="card-body">
                <div >
                  <div className="icon mb-3" style={{ fontSize: '2rem', color: '#8ad1c0' }}>
                    <i className="bi bi-person-circle"></i> {/* Bootstrap icon */}
                  </div>
                  <h4 className="card-title">Enquire</h4>
                  <p className="card-text">Get to know about our services</p>
                </div>
              </div>
            </div>
          </Link>

          <Link className="col-md-4 mb-4" to={'/bookAppointment'}>
            <div className="card shadow-sm">
              <div className="card-body">
                <div className="icon mb-3" style={{ fontSize: '2rem', color: '#ef929e' }}>
                  <i className="bi bi-calendar-event"></i>
                </div>
                <h4 className="card-title">Book Appointments</h4>
                <p className="card-text">Book appointment for your services</p>
              </div>
            </div>
          </Link>
          <Link className="col-md-4 mb-4" data-aos="fade-up" data-aos-delay="100" to={'/selfCheckin'}>
            <div className="card shadow-sm">
              <div className="card-body">
                <div className="icon mb-3" style={{ fontSize: '2rem', color: '#f9b9a6' }}>
                  <i className="bi bi-person-check"></i>
                </div>
                <h4 className="card-title">Instant/Self Checkin</h4>
                <p className="card-text">Register prior to your arrival</p>
              </div>
            </div>
          </Link>

          <Link className="col-md-4 mb-4" data-aos="fade-up" data-aos-delay="100" to={'/packages'}>
            <div className="card shadow-sm">
              <div className="card-body">
                <div className="icon mb-3" style={{ fontSize: '2rem', color: '#f4afc2' }}>
                  <i className="bi bi-box"></i>
                </div>
                <h4 className="card-title">Packages</h4>
                <p className="card-text">Find packages that suit your need</p>
              </div>
            </div>
          </Link>

          {/* New Cards */}
          <Link className="col-md-4 mb-4" data-aos="fade-up" data-aos-delay="100" to={'/memberships'}>
            <div className="card shadow-sm">
              <div className="card-body">
                <div className="icon mb-3" style={{ fontSize: '2rem', color: '#FAD02C' }}>
                  <i className="bi bi-star-fill"></i>
                </div>
                <h4 className="card-title">Memberships</h4>
                <p className="card-text">Become our member for exciting discounts</p>
              </div>
            </div>
          </Link>

          <Link className="col-md-4 mb-4" data-aos="fade-up" data-aos-delay="100" to={'/offers'}>
            <div className="card shadow-sm">
              <div className="card-body">
                <div className="icon mb-3" style={{ fontSize: '2rem', color: '#8CB8A8' }}>
                  <i className="bi bi-tags-fill"></i>
                </div>
                <h4 className="card-title">Offers</h4>
                <p className="card-text">Check out the latest offers</p>
              </div>
            </div>
          </Link>

          <Link className="col-md-4 mb-4" data-aos="fade-up" data-aos-delay="100" to={'/galleryPage'}>
            <div className="card shadow-sm">
              <div className="card-body">
                <div className="icon mb-3" style={{ fontSize: '2rem', color: '#F89991' }}>
                  <i className="bi bi-images"></i>
                </div>
                <h4 className="card-title">Photo Gallery</h4>
                <p className="card-text">Our Facilities and latest work portfolio</p>
              </div>
            </div>
          </Link>

          <Link className="col-md-4 mb-4" data-aos="fade-up" data-aos-delay="100" to={'/serviceproviders'}>
            <div className="card shadow-sm">
              <div className="card-body">
                <div className="icon mb-3" style={{ fontSize: '2rem', color: '#77A6C5' }}>
                  <i className="bi bi-people-fill"></i>
                </div>
                <h4 className="card-title">Service Providers</h4>
                <p className="card-text">Achievements & Qualifications of our Team</p>
              </div>
            </div>
          </Link>

          <Link className="col-md-4 mb-4" data-aos="fade-up" data-aos-delay="100" to={'/reviews'}>
            <div className="card shadow-sm">
              <div className="card-body">
                <div className="icon mb-3" style={{ fontSize: '2rem', color: '#88C2CB' }}>
                  <i className="bi bi-google"></i>
                </div>
                <h4 className="card-title">Reviews</h4>
                <p className="card-text">Check out our Google Reviews</p>
              </div>
            </div>
          </Link>

          <Link className="col-md-4 mb-4" data-aos="fade-up" data-aos-delay="100" to={'/feedback'}>
            <div className="card shadow-sm">
              <div className="card-body">
                <div className="icon mb-3" style={{ fontSize: '2rem', color: '#7CAFB1' }}>
                  <i className="bi bi-chat-left-text-fill"></i>
                </div>
                <h4 className="card-title">Feedback</h4>
                <p className="card-text">Give us your genuine suggestions</p>
              </div>
            </div>
          </Link>
        </div>

        {/* WhatsApp Button */}
        <div className="position-fixed" style={{ bottom: '20px', right: '20px', zIndex: 9999999999 }}>
          <a href="https://wa.me/+918400081708" target="_blank" rel="noopener noreferrer">
            <img
              src="assets//img//WhatsApp_icon.png" // Add WhatsApp icon image path
              alt="WhatsApp"
              style={{ width: '60px', height: '60px' }}
            />
          </a>
        </div>

      </div>
      <Footer />
    </div>
  );
}

export default SalonPage;
