import React, { useEffect, useState } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Loader from '../Component/Loader'
function Contact() {
    useEffect(() => {
        AOS.init({
            duration: 1000,  // Animation duration in milliseconds
            once: true,      // Animation should happen only once
        });
    }, []);
    const [name, setname] = useState('')
    const [email, setemail] = useState('')
    const [subject, setsubject] = useState('')
    const [message, setmessage] = useState('')
    const [resultmessage, setresultmessage] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const Submit = () => {
        if (name == '' || !name) {
            alert('Enter a Valid name')
            return
        }
        if (email == '' || !email || email && !/\S+@\S+\.\S+/.test(email)) {
            alert('Enter a Valid email')
            return
        }
        try {
            setIsLoading(true)
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            const raw = JSON.stringify({
                "email": email,
                "name": name,
                "subject": subject,
                "message": message
            });
            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };
            fetch("https://reboondingbackend.onrender.com/api/contactus/addcontactus", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    setresultmessage(result.message)
                    setname('')
                    setemail('')
                    setmessage('')
                    setsubject('')
                    setIsLoading(false)
                })
                .catch((error) => console.error(error), setIsLoading(false));

        } catch (e) {
            alert(e.message)
            setIsLoading(false)
        }
    }
    return (
        <>
            <section id="contact" className="contact section light-background">
                <Loader
                    message={`Please Wait..`}
                    isLoading={isLoading}
                />
                <div className="container section-title" data-aos="fade-up">
                    <h2>Contact</h2>
                    <div><span>Need Help?</span> <span className="description-title">Contact Us</span></div>
                </div>

                <div className="container" data-aos="fade-up" data-aos-delay="100">

                    <div className="row gy-4">
                        <div className="col-lg-6 ">
                            <div className="row gy-4">

                                <div className="col-lg-12">
                                    <div className="info-item d-flex flex-column justify-content-center align-items-center" data-aos="fade-up" data-aos-delay="200">
                                        <i className="bi bi-geo-alt"></i>
                                        <h3>Near Chetmani Chauraha</h3>
                                        <p>Jawahar Nagar, Durgakund Varanasi, Uttar Pradesh 221005</p>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="info-item d-flex flex-column justify-content-center align-items-center" data-aos="fade-up" data-aos-delay="300">
                                        <i className="bi bi-telephone"></i>
                                        <h3>Call Us</h3>
                                        <p>+91 8400081708</p>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="info-item d-flex flex-column justify-content-center align-items-center" data-aos="fade-up" data-aos-delay="400">
                                        <i className="bi bi-envelope"></i>
                                        <h3>Email Us</h3>
                                        <p>info@reboonding.com</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="php-email-form" data-aos="fade-up" data-aos-delay="100">
                                <div className="row gy-4">

                                    <div className="col-md-6">
                                        <input type="text" style={{ borderWidth: 1.5, borderColor: '#e0e0e0', }} name="name" className="form-control" placeholder="Your Name" required="" onChange={(e) => { setname(e.target.value); setresultmessage('') }} value={name} />
                                    </div>

                                    <div className="col-md-6 ">
                                        <input type="email" style={{ borderWidth: 1.5, borderColor: '#e0e0e0', }} className="form-control " name="email" placeholder="Your Email" required="" onChange={(e) => { setemail(e.target.value); setresultmessage('') }} value={email} />
                                    </div>

                                    <div className="col-md-12">
                                        <input type="text" style={{ borderWidth: 1.5, borderColor: '#e0e0e0', }} className="form-control" name="subject" placeholder="Subject" onChange={(e) => { setsubject(e.target.value); setresultmessage('') }} value={subject} />
                                    </div>

                                    <div className="col-md-12">
                                        <textarea style={{ borderWidth: 1.5, borderColor: '#e0e0e0', }} className="form-control" name="message" rows="4" placeholder="Message" onChange={(e) => { setmessage(e.target.value); setresultmessage('') }} value={message} />
                                    </div>
                                    <div className="col-md-12">
                                        <div className="">{resultmessage}</div>
                                    </div>

                                    <div className="col-md-12 text-center">
                                        <button style={{ width: 320, padding: 5 }} className="othbutton" onClick={Submit} >Send Message</button>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </section>
        </>

    )
}

export default Contact