import React, { useState } from 'react';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import { Link } from 'react-router-dom';
// import Gallery from '../Component/gallery';

const GalleryPage = () => {
  return (
    <>
      <Header />
      <div className="container section-title">
        <h2>Gallery</h2>
        <div>
          {/* <span>Learn More</span> */}
          <span className="description-title">Gallery</span>
        </div>
      </div>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="container">

            <div className="row gy-4" >
              <div className="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                <div className="member">
                  <img src="assets/img/Gallery/galleryphoto-1.png" className="img-fluid" alt="" />
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                <div className="member">
                  <img src="assets/img/Gallery/galleryphoto-2.png" className="img-fluid" alt="" />
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                <div className="member">
                  <img src="assets/img/Gallery/galleryphoto-3.png" className="img-fluid" alt="" />
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                <div className="member">
                  <img src="assets/img/Gallery/galleryphoto-4.png" className="img-fluid" alt="" />
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                <div className="member">
                  <img src="assets/img/Gallery/galleryphoto-5.png" className="img-fluid" alt="" />
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                <div className="member">
                  <img src="assets/img/Gallery/galleryphoto-6.png" className="img-fluid" alt="" />
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                <div className="member">
                  <img src="assets/img/Gallery/galleryphoto-7.png" className="img-fluid" alt="" />
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                <div className="member">
                  <img src="assets/img/Gallery/galleryphoto-8.png" className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GalleryPage;
