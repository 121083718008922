import React, { useState } from 'react';
import { Link } from 'react-router-dom';
const Footer = () => {
    const [email, setemail] = useState('')
    const [error, seterror] = useState('')
    const [message, setmessage] = useState('')
    const submit = () => {
        if (email == '' || !email || email && !/\S+@\S+\.\S+/.test(email)) {
            seterror('Enter a Valid email')
            return
        }
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "email": email
            });
            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch("https://reboondingbackend.onrender.com/api/newsletter/addnewsletteremail", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    setmessage(result.message)
                })
                .catch((error) => console.error(error));
        } catch (e) {
            alert(e.message)
        }
    }
    return (
        <footer id="footer" className="footer dark-background">

            <div className="container footer-top">
                <div className="row gy-4">
                    <div className="col-lg-4 col-md-6 footer-about">
                        <a href="index.html" className="logo d-flex align-items-center">
                            <img src="assets/img/reboonding_logo_lightMood.png" alt="" />
                        </a>
                        <div className="footer-contact pt-3">
                            <p>Near Chetmani Chauraha</p>
                            <p>Jawahar Nagar, Durgakund</p>
                            <p>Varanasi, Uttar Pradesh 221005</p>
                            <p className="mt-3"><strong>Phone:</strong> <a href='tel:8400081708'>+91 8400081708</a></p>
                            {/* <p><strong>Email:</strong> <span>info@example.com</span></p> */}
                        </div>
                        <div className="social-links d-flex mt-4">
                            {/* <a href=""><i className="bi bi-twitter-x"></i></a> */}
                            <a href=""><i className="bi bi-facebook"></i></a>
                            <a href="https://www.instagram.com/re_boonding?igsh=eWxtYjJybmQ5OGNw" target='_blank'><i className="bi bi-instagram"></i></a>
                            {/* <a href=""><i className="bi bi-linkedin"></i></a> */}
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-3 footer-links">
                        <h4>Useful Links</h4>
                        <ul>
                            <li><Link to={'/'}>Home</Link></li>
                            <li><Link to={'/#'}>Services</Link></li>
                            <li><Link to={'/#'}>Portfolio</Link></li>
                            <li><Link to={'/#'}>Gallery</Link></li>
                            <li><Link to={'/#'}>Contct</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-2 col-md-3 footer-links">
                        <h4>Our Services</h4>
                        <ul>
                            <li><Link to="/#">BRIDAL SERVICES</Link></li>
                            <li><Link to="/#">HAIR TREATMENT</Link></li>
                            <li><Link to="/#">SKIN TREATMENT</Link></li>
                            <li><Link to="/#">HYDRA FACIAL</Link></li>
                            <li><Link to="/#">NAILS ART</Link></li>
                            <li><Link to="/#">TATTOO</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-4 col-md-12 footer-newsletter">
                        <h4>Our Newsletter</h4>
                        <p>Subscribe to our newsletter and receive the latest news about our products and services!</p>
                        {/* <form action="forms/newsletter.php" method="post" className="php-email-form"> */}
                        <div className="newsletter-form">
                            <input onChange={(e) => { seterror(''); setemail(e.target.value); setmessage('') }} type="email" name="email" />
                            <input onClick={submit} style={{ width: 120 }} className="button" value="Subscribe" />
                        </div>
                        {/* <div className="loading">Loading</div> */}
                        <div className="error-message">{error}</div>
                        <div className="sent-message">{message}</div>
                        {/* </form> */}
                    </div>

                </div>
            </div>

            <div className="container copyright text-center mt-4">
                <p>© <span>Copyright</span> <strong className="px-1 sitename">Re-Boonding Family Salon</strong> <span>All Rights Reserved</span></p>
                <div className="credits">
                    Designed by <a href="https://reboonding.com/">Re-Boonding</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
