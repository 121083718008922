import React from 'react';
const Popup = ({ message, isLoading, onClose }) => {
  if (!isLoading) return null;

  return (
    <div className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center bg-dark bg-opacity-50 " style={{ zIndex: 99999999999 }}>
      <div className="bg-white p-4 rounded shadow-lg w-50 max-w-md">
        <div className="d-flex justify-content-center align-items-center" >
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
        <p className="text-center text-secondary fs-5">
          {message}
        </p>
      </div>
    </div>
  );
};

export default Popup;
