import React, { useState, useEffect } from 'react';
import './SalonBookingPage.css';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import Loader from '../Component/Loader'
const BookAppointment = () => {
  const now = new Date();
  const currentDate = now.toISOString().split('T')[0];
  const currentTime = now.getHours() + 1 + ':' + (now.getMinutes() < 30 ? '00' : '30');
  const [isLoading, setIsLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [cart, setCart] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [appointmentTime, setAppointmentTime] = useState(currentTime);
  const [appointmentdate, setAppointmentdate] = useState(currentDate);
  const [name, setname] = useState('');
  const [number, setnumber] = useState('');
  const [Clint, setClint] = useState('')
  const [BookinConfirmationi, setBookinConfirmationi] = useState('')
  // Fetch service options from the API
  useEffect(() => {
    try {
      setIsLoading(true)
      fetch('https://reboondingbackend.onrender.com/api/services/getallservices') // replace with your API endpoint
        .then((response) => response.json())
        .then((data) => {
          setServices(data)
          // console.log(data)
          setClint({ number, name, appointmentdate: currentDate, appointmentTime: currentTime })
          setIsLoading(false)
        })
        .catch((error) => {
          console.error('Error fetching services:', error)
          setIsLoading(false)
        });
    } catch (e) {
      alert(e)
    }
  }, []);

  // Generate time options for 30-minute intervals
  function generateTimeOptions() {
    const times = [];
    let startTime = 10 * 60; // 10:00 AM in minutes
    const endTime = 21 * 60; // 09:00 PM (21:00) in minutes

    while (startTime <= endTime) {
      const hours = Math.floor(startTime / 60);
      const minutes = startTime % 60;
      const timeString = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
      times.push(timeString);
      startTime += 30; // Increment time by 30 minutes
    }

    return times;
  }
  function isPastTime(time, appointmentDate) {
    const now = new Date();
    const appointment = new Date(appointmentDate); // Parse the selected appointment date

    // If the appointment date is not today, do not disable any time
    if (appointment.toDateString() !== now.toDateString()) {
      return false;
    }

    // If the appointment date is today, check the time
    const [hours, minutes] = time.split(':').map(Number);
    const timeDate = new Date();
    timeDate.setHours(hours, minutes, 0, 0);

    return timeDate < now; // Disable past times for today
  }

  // Handle adding services to the cart
  const handleAddToCart = (option) => {
    if (cart.some((item) => item._id === option._id)) {
      alert('Item is already added to the cart.');
      return;
    }
    setCart((prevCart) => [...prevCart, option]);
    setTotalPrice((prevTotal) => prevTotal + option.price);
  };

  // Handle removing an item from the cart
  const handleRemoveFromCart = (_id) => {
    setCart((prevCart) => {
      const updatedCart = prevCart.filter((item) => item._id !== _id);
      const removedItem = prevCart.find((item) => item._id === _id);
      const updatedTotalPrice = updatedCart.reduce((total, item) => total + item.price, 0);
      setTotalPrice(updatedTotalPrice);
      return updatedCart;
    });
  };
  const ondate = new Date();
  const currentHour = ondate.getHours();
  const currentMinute = ondate.getMinutes();
  // Handle form submission
  const handleSubmit = () => {
    console.log(cart, 'cart')
    if (name == '' || !name) {
      alert('name is required')
      return
    }
    if (number == '' || !number) {
      alert('number is required')
      return
    }
    if (number.length !== 10) {
      alert('Enter Valid Mobile Number');
      return;
    }
    if (cart.length === 0) {
      alert('Cart is empty, please add at least one service.')
      return
    }
    // console.log('Form data:', JSON.stringify({ cart, totalPrice, Clint }));
    try {
      setIsLoading(true)
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({ cart, totalPrice, Clint });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch("https://reboondingbackend.onrender.com/api/appointment/addappointment", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setBookinConfirmationi(result)
          alert(result.message)
          alert(`Your Booking Id is ${result.appointmentData.bookingId}`)
          setname('')
          setnumber('')
          setIsLoading(false)
        })
        .catch((error) => {
          console.error(error)
          setIsLoading(false)
        })
    } catch (e) {
      alert(e)
      setIsLoading(false)
    }

    // Additional logic for form submission
  };

  return (
    <>
      <Header />
      <div className="salon-booking-page py-5">
        <div className="container">
          <Loader
            message={`Please Wait..`}
            isLoading={isLoading}
          />
          <div className="row">
            {/* Left Section (Form Section) */}
            <div className="col-md-8 mb-4">
              <div className="p-4 bg-white shadow-sm rounded">
                <div onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="appointmentDate" className="form-label">Appointment Date<span style={{ color: 'red' }}>*</span></label>
                      <input
                        type="date"
                        id="appointmentDate"
                        name="appointmentDate"
                        className="form-control"
                        defaultValue={currentDate}
                        onChange={(e) => {
                          setAppointmentdate(e.target.value)
                          setClint({ number, name, appointmentdate: e.target.value, appointmentTime })
                        }}
                        required
                      />
                    </div>
                    <div className="col-md-3 col-6">
                      <label htmlFor="appointmentTime" className="form-label">Appointment Time<span style={{ color: 'red' }}>*</span></label>
                      <select
                        id="appointmentTime"
                        name="appointmentTime"
                        className="form-control"
                        value={appointmentTime}
                        onChange={(e) => {
                          setAppointmentTime(e.target.value)
                          setClint({ number, name, appointmentdate, appointmentTime: e.target.value })
                        }}
                        required
                      >
                        {generateTimeOptions().map((time) => (
                          <option disabled={isPastTime(time, appointmentdate)} key={time} value={time}>{time}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-3 col-6">
                      <label htmlFor="Promo" className="form-label">Promo Code</label>
                      <input
                        type="text"
                        id="Promo"
                        name="Promo"
                        className="form-control"
                        // defaultValue={currentDate}
                        placeholder='Promo Code'
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="name" className="form-label">Name<span style={{ color: 'red' }}>*</span></label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        onChange={(e) => {
                          setname(e.target.value)
                          setClint({ number, name: e.target.value, appointmentdate, appointmentTime })
                        }}
                        value={name}
                        required
                        placeholder='Enter Name'
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="number" className="form-label">Number<span style={{ color: 'red' }}>*</span></label>
                      <input
                        type="number"
                        id="number"
                        name="number"
                        className="form-control"
                        onChange={(e) => {
                          setnumber(e.target.value)
                          setClint({ number: e.target.value, name, appointmentdate, appointmentTime })
                        }}
                        value={number}
                        required
                        placeholder='Enter Number'
                      />
                    </div>
                  </div>

                  {/* Services Section */}
                  <div className="mb-4">
                    <h4>Select Services</h4>
                    <ul className="list-unstyled">
                      {services.map((service, index) => (
                        <li key={index} className="mb-3">
                          <details>
                            <summary className="fw-bold">{service.name}</summary>
                            <div className="ps-3">
                              {service.options.map((option) => (
                                <div key={option.id} className="border d-flex justify-content-between align-items-center p-2">
                                  <p className="mb-0">{option.name} - ₹{option.price}</p>
                                  <button style={{ backgroundColor: '#ff6347', color: '#fff' }} className="btn btn-sm btn-secondary ms-3" onClick={() => handleAddToCart(option)}>
                                    Add to Cart
                                  </button>
                                </div>
                              ))}
                            </div>
                          </details>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Section (Cart Section) */}
            <div className="col-md-4">
              <div className="p-4 bg-white shadow-sm rounded">
                <h4>Your Cart</h4>
                {cart.length === 0 ? (
                  <p>Your cart is empty</p>
                ) : (
                  <ul className="list-unstyled">
                    {cart.map((item) => (
                      <li key={item.id} className="d-flex justify-content-between align-items-center mb-2">
                        <span>{item.name} - ₹{item.price}</span>
                        <button className="btn btn-sm btn-danger" onClick={() => handleRemoveFromCart(item._id)}>
                          Remove
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
                <div className="d-flex justify-content-between mt-4">
                  <p>Total (INR)</p>
                  <p className="fs-3 fw-bold">₹{totalPrice.toFixed(2)}</p>
                </div>
                <button style={{ backgroundColor: '#ff6347', color: '#fff' }} className="btn btn-secondary w-100" onClick={handleSubmit}>
                  Book Appointment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BookAppointment;
