import React, { useState, useEffect } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Header from '..//Component/Header'
import Footer from '../Component/Footer';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import Loader from '../Component/Loader'
import 'aos/dist/aos.css';
const SalonForm = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,  // Animation duration in milliseconds
      once: true,      // Animation should happen only once
    });
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setemail] = useState('');
  const [name, setname] = useState('');
  const [mobile, setmobile] = useState('');
  const [Interest, setInterest] = useState('');
  const [hearaboutus, sethearaboutus] = useState('');
  const [resultmessage, setresultmessage] = useState('')
  const handleSubmit = () => {
    if (name == '' || !name) {
      alert('name is required')
      return
    }
    if (mobile == '' || !mobile) {
      alert('mobile is required')
      return
    }
    if (email == '' || !email) {
      alert('email is required')
      return
    }
    if (Interest == '' || !Interest) {
      alert('Interest is required')
      return
    }
    try {
      setIsLoading(true)
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "email": email,
        "name": name,
        "mobile": mobile,
        "Interest": Interest,
        "hearaboutus": hearaboutus
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch("https://reboondingbackend.onrender.com/api/enquiry/addenquiry", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result)
          setresultmessage(result.message)
          alert(result.message)
          setemail('')
          setname('')
          setmobile('')
          setInterest('')
          sethearaboutus('')
          setIsLoading(false)
        })
        .catch((error) => {
          console.error(error)
          setIsLoading(false)
        });
    } catch (e) {
      alert(e)
      setIsLoading(false)
    }

  };

  return (
    <>
      <Header />
      <div className="container py-5">
        <Loader
          message={`Please Wait..`}
          isLoading={isLoading}
        />
        <div style={{ backgroundColor: '#e0e0e0', borderRadius: 15 }} className="shadow-lg p-4">
          <h2 className="text-center mb-4">
            Get the Latest Salon Offers & Updates via SMS & Email!
          </h2>
          <p className="text-center ">
            {resultmessage}
          </p>
          <div className="needs-validation" data-aos="fade-up" data-aos-delay="100">
            <div className="mb-3">
              <label style={{ color: '#000' }} htmlFor="fullName" className="form-label">Full Name <span style={{ color: 'red' }}>*</span></label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={name}
                onChange={(e) => { setname(e.target.value); setresultmessage('') }}
                className="form-control"
                placeholder="Full Name"
                required
              />
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label style={{ color: '#000' }} htmlFor="mobileNumber" className="form-label">Mobile Number <span style={{ color: 'red' }}>*</span></label>
                <input
                  type="text"
                  id="mobileNumber"
                  name="mobileNumber"
                  value={mobile}
                  onChange={(e) => { setmobile(e.target.value); setresultmessage('') }}
                  className="form-control"
                  placeholder="Mobile Number*"
                  required
                />
              </div>

              <div className="col-md-6 mb-3">
                <label style={{ color: '#000' }} htmlFor="email" className="form-label">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => { setemail(e.target.value); setresultmessage('') }}
                  className="form-control"
                  placeholder="Email"
                />
              </div>
            </div>

            <div className="mb-3">
              <label style={{ color: '#000' }} htmlFor="interestIn" className="form-label">Interest In</label>
              <select
                id="interestIn"
                name="interestIn"
                value={Interest}
                onChange={(e) => { setInterest(e.target.value); setresultmessage('') }}
                className="form-select"
                required
              >
                <option value="">Select</option>
                <option value="sr,158">Casmara Skin Sensation</option>
                <option value="sr,159">Casmara  Goji Facial</option>
                <option value="sr,160">Casmara Regrian Facial</option>
                <option value="sr,161">Biolight Miracle W</option>
                <option value="sr,162">Casmara Luxury Facial</option>
                <option value="sr,163">Casmara Nacar Facial</option>
                <option value="sr,177">Basic Clean up</option>
                <option value="sr,178">Birilliance White Clean up</option>
                <option value="sr,179">Hydra boost Cleanup W</option>
                <option value="sr,180">Casmara Cleanup</option>
                <option value="sr,181">Upper Lips Rica Wax</option>
                <option value="sr,182">Chin Rica wax</option>
                <option value="sr,183">Forehead Rica Wax</option>
                <option value="sr,184">Side Lock Rica Wax</option>
                <option value="sr,185">Neck Rica Wax</option>
                <option value="sr,186">Under Arm's Rica</option>
                <option value="sr,187">Full Face Rica Wax</option>
                <option value="sr,188">Half Arms Rica Wax</option>
                <option value="sr,189">Full Arms Rica Wax</option>
                <option value="sr,190">Half Leg Rica Wax</option>
                <option value="sr,191">Full Leg Rica Wax</option>
                <option value="sr,192">Half Front/Back Rica Wax</option>
                <option value="sr,195">Brazillian Bikni Rica Wax</option>
                <option value="sr,196">Change polish ( Basic)</option>
                <option value="sr,197">Cutting/Filling/Paint</option>
                <option value="sr,199">Regular Manicure</option>
                <option value="sr,200">Regular Pedicure</option>
                <option value="sr,201">Crystal Spa Pedicure</option>
                <option value="sr,202">Crystall spa Manicure</option>
                <option value="sr,203">Exclusive Pedicure</option>
                <option value="sr,204">Coconut Oil</option>
                <option value="sr,205">Olive Oil</option>
                <option value="sr,206">Almond Oil</option>
                <option value="sr,207">Moroccan</option>
                <option value="sr,208">Body Polishing Spa</option>
                <option value="sr,210">Schwarzkopf Hair Spa</option>
                <option value="sr,211">GK Hair Spa Man's</option>
                <option value="sr,212">Moroccan Oil Hair Spa Man's</option>
                <option value="sr,213">Anti-Dandruff Treatment</option>
                <option value="sr,214">Anti-Hairfall Treatment</option>
                <option value="sr,215">Hair Repair Treatment</option>
                <option value="sr,216">Fiber Plex Treatment</option>
                <option value="sr,217">GK Fast Blow Dry</option>
                <option value="sr,218">Keratin Treatment Shoulder Length</option>
                <option value="sr,219">Keratin Treatment Mid Length</option>
                <option value="sr,220">Keratin Treatment Waist Length</option>
                <option value="sr,221">Kera Smooth Shoulder Length</option>
                <option value="sr,222">Kera Smooth Mid Length</option>
                <option value="sr,223">Kera Smooth Waist  Length</option>
                <option value="sr,224">Light Party Makeup( Kryolan)</option>
                <option value="sr,226">Exclusive Party makeup ( MAC)</option>
                <option value="sr,227">Party AirBrush Makeup</option>
                <option value="sr,230">Exclusive HD Makeup Engagement Makeup</option>
                <option value="sr,232">Basic Bridal Makeup</option>
                <option value="sr,233">Bridal Makeup HD</option>
                <option value="sr,245">Side  Locks threading</option>
                <option value="sr,248">Upperlips/chin/ Lower Lip Threading</option>
                <option value="sr,249">Neck Threading</option>
                <option value="sr,251">Treandy Per Foil Streaks W</option>
                <option value="sr,252">Neck Length Color W</option>
                <option value="sr,253">Shoulder Length Color W</option>
                <option value="sr,254">Mid Back Length Color W</option>
                <option value="sr,255">Below Waist Length Color W</option>
                <option value="sr,256">Neck Length Ombre Color W</option>
                <option value="sr,257">Shoulder Length Ombre Color W</option>
                <option value="sr,258">Mid Back Length Ombre Color</option>
                <option value="sr,259">Below Waist Length Ombre Color</option>
                <option value="sr,260">Neck Length Balayage Color</option>
                <option value="sr,261">Shoulder Length Balayage Color</option>
                <option value="sr,262">Mid Back Length Balayage Color</option>
                <option value="sr,263">Below waist Length Balayage Color</option>
                <option value="sr,266">Child Hair Cut Under 12</option>
                <option value="sr,267">One Length Trimming</option>
                <option value="sr,268">Blow Dry Setting</option>
                <option value="sr,269">Shampoo+ Blow Dry</option>
                <option value="sr,270">Shampoo + Conditioner</option>
                <option value="sr,271">Split End Removal</option>
                <option value="sr,273">Nourishing Haircut</option>
                <option value="sr,388">Dtan Face</option>
                <option value="sr,389">Femail Hair cut without wash</option>
                <option value="sr,390">Femail Hair cut with wash</option>
                <option value="sr,391">Hair cut without wash</option>
                <option value="sr,392">Hair Cut with wash</option>
                <option value="sr,393">Dtan Face neck &amp;amp;amp; V line</option>
                <option value="sr,394">Schwarzkopf Hair color</option>
                <option value="sr,395">Eyebrows</option>
                <option value="sr,396">Shaving</option>
                <option value="sr,397">Lorial Hair Spa</option>
                <option value="sr,398">Reboonding short lenth</option>
                <option value="sr,399">Dtan face &amp;amp;amp;amp; Neck</option>
                <option value="sr,400">Kara Smoothning (short length)6</option>
                <option value="sr,401">Kara Smoothning (Long length)</option>
                <option value="sr,402">Kara Smoothning (Mid Length)</option>
                <option value="sr,403">Beard Styling</option>
                <option value="sr,404">Fruit Facial</option>
                <option value="sr,405">Men's Highlights (Per foil)</option>
                <option value="sr,406">Groom Make up</option>
                <option value="sr,407">Beard color</option>
                <option value="sr,408">Anti Dendroff Treatment</option>
                <option value="sr,409">Hair Smoothing Man's</option>
                <option value="sr,410">Keratin Treatment Man's</option>
                <option value="sr,411">Advance Clean up</option>
                <option value="sr,412">Kanpeki fruits Facial</option>
                <option value="sr,413">Kanpeki Gensyl Facial</option>
                <option value="sr,414">Kanpeki Dermasyl Facial</option>
                <option value="sr,415">Casmara Advance Facial</option>
                <option value="sr,416">Laxary Advance Facial</option>
                <option value="sr,417">Half Arm's Rica</option>
                <option value="sr,418">Full Arm's Rica</option>
                <option value="sr,419">Half Leg's Rica</option>
                <option value="sr,420">Full Leg's Rica</option>
                <option value="sr,421">Full Front Rica</option>
                <option value="sr,422">Full Back Rica</option>
                <option value="sr,423">Root touchup</option>
                <option value="sr,424">Hair wash Dryle female</option>
                <option value="sr,425">Hair Wash Male</option>
                <option value="sr,426">TEEJ OFFER PACKAGE</option>
                <option value="sr,427">GK Karetin mid leanth</option>
                <option value="sr,428">Full hand wax normal</option>
                <option value="sr,429">Hair color service charge</option>
                <option value="sr,430">TEEJ offer packej</option>
                <option value="sr,431">Head massage</option>
                <option value="mem,1">GOLDEN MEMBER SHIP</option>
                <option value="mem,2">reboonding family salon</option>
                <option value="mem,3">reboonding mambership</option>
                <option value="mem,4">utakars</option>
              </select>
            </div>

            <div className="mb-3">
              <label style={{ color: '#000' }} htmlFor="heardAbout" className="form-label">How did you hear about us?</label>
              <select
                id="heardAbout"
                name="heardAbout"
                value={hearaboutus}
                onChange={(e) => { sethearaboutus(e.target.value); setresultmessage('') }}
                className="form-select"
              >
                <option value="">Select</option>
                <option value="Client refrence">Client refrence</option>
                <option value="Cold Calling">Cold Calling</option>
                <option value="Facebook">Facebook</option>
                <option value="Twitter">Twitter</option>
                <option value="Instagram">Instagram</option>
                <option value="Other Social Media">Other Social Media</option>
                <option value="Website">Website</option>
                <option value="Walk-In">Walk-In</option>
                <option value="Flex">Flex</option>
                <option value="Flyer">Flyer</option>
                <option value="Newspaper">Newspaper</option>
                <option value="SMS">SMS</option>
                <option value="Street Hoardings">Street Hoardings</option>
                <option value="Event">Event</option>
                <option value="TV/Radio">TV/Radio</option>
              </select>
            </div>

            <div className="text-center">
              <Link onClick={handleSubmit} className="btn-get-started" style={{ backgroundColor: '#ff6347', color: '#fff', padding: '10px 20px', borderRadius: '5px' }}>
                Submit
              </Link>
            </div>
          </div>
        </div>
        <div className="position-fixed" style={{ bottom: '20px', right: '20px', zIndex: 9999999999 }}>
          <a href="https://wa.me/+918400081708" target="_blank" rel="noopener noreferrer">
            <img
              src="assets//img//WhatsApp_icon.png" // Add WhatsApp icon image path
              alt="WhatsApp"
              style={{ width: '60px', height: '60px' }}
            />
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SalonForm;
