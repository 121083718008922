import React, { useState, useEffect } from 'react';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Loader from '../Component/Loader'
const FeedbackForm = () => {
  const [Suggestion, setSuggestion] = useState('')
  const [Number, setNumber] = useState('')
  const [Name, setName] = useState('')
  const [resultmessage, setresultmessage] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const submit = () => {
    if (Suggestion == '' || !Suggestion) {
      alert('Suggestion are required')
      return
    }
    if (Name == '' || !Name) {
      alert('Name are required')
      return
    }
    if (Number == '' || !Number) {
      alert('Number are required')
      return
    }
    try {
      setIsLoading(true)
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "number": Number,
        "name": Name,
        "suggestion": Suggestion
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch("https://reboondingbackend.onrender.com/api/feedback/addnewfeedback", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result)
          setresultmessage(result.message)
          setName('')
          setNumber('')
          setSuggestion('')
          setIsLoading(false)
        })
        .catch((error) => console.error(error));
    } catch (e) {
      alert(e)
    }
  };
  useEffect(() => {
    AOS.init({
      duration: 1000,  // Animation duration in milliseconds
      once: true,      // Animation should happen only once
    });
  }, []);
  return (
    <>
      <Header />
      <div className="container py-5">
        <Loader
          message={`Please Wait..`}
          isLoading={isLoading}
        />
        <div className="shadow-lg p-4" style={{ backgroundColor: '#e0e0e0', borderRadius: 15 }}>
          <h3 className="text-center mb-4">Please let us know...</h3>
          <p className='text-center'>{resultmessage}</p>
          <div className="needs-validation" data-aos="fade-up" data-aos-delay="100">
            <div className="mb-3">
              <label htmlFor="feedback" className="form-label" style={{ color: '#000' }}>
                Your Feedback/Suggestion
              </label>
              <textarea
                id="feedback"
                name="feedback"
                value={Suggestion}
                onChange={(e) => { setSuggestion(e.target.value); setresultmessage('') }}
                className="form-control"
                placeholder="Your Feedback/Suggestion"
                rows="4"
                required
              ></textarea>
            </div>

            <div className="mb-3">
              <label htmlFor="fullName" className="form-label" style={{ color: '#000' }}>
                Full Name
              </label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={Name}
                onChange={(e) => { setName(e.target.value); setresultmessage('') }}
                className="form-control"
                placeholder="Full Name"
                required
              />
            </div>

            <div className="mb-5">
              <label htmlFor="contactNumber" className="form-label" style={{ color: '#000' }}>
                Contact Number
              </label>
              <input
                type="number"
                id="contactNumber"
                name="contactNumber"
                value={Number}
                onChange={(e) => { setNumber(e.target.value); setresultmessage('') }}
                className="form-control"
                placeholder="Contact Number"
                required
              />
            </div>

            <div className="text-center">
              <Link onClick={submit} className="btn-get-started" style={{ backgroundColor: '#ff6347', color: '#fff', padding: '10px 20px', borderRadius: '5px' }}>
                Submit
              </Link>
            </div>
          </div>
        </div>
        <div className="position-fixed" style={{ bottom: '20px', right: '20px', zIndex: 9999999999 }}>
          <a href="https://wa.me/+918400081708" target="_blank" rel="noopener noreferrer">
            <img
              src="assets//img//WhatsApp_icon.png" // Add WhatsApp icon image path
              alt="WhatsApp"
              style={{ width: '60px', height: '60px' }}
            />
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FeedbackForm;
