import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function Carousel() {
    useEffect(() => {
        const carouselElement = document.querySelector('#hero-carousel');
        if (carouselElement) {
            new window.bootstrap.Carousel(carouselElement, {
                interval: 5000,
                ride: 'carousel',
                pause: false,
            });
        }
    }, []);

    return (
        <section id="hero" className="hero section dark-background" style={{ position: 'relative' }}>
            <div id="hero-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="5000">
                <div className="carousel-inner" style={{ height: '80vh' }}>
                    <div className="carousel-item active">
                        <img
                            src="assets/img/hero-carousel/slider-1.png"
                            alt="Slide 1"
                            className="d-block w-100"
                            style={{ objectFit: 'cover' }}
                        />
                        <div
                            className="carousel-container d-flex flex-column justify-content-center align-items-center"
                            style={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                color: '#fff',
                                textAlign: 'center',
                            }}
                        >
                            <h2>Reboonding Family Salon</h2>
                            <p>Feel Fabulous, Look Incredible</p>
                            <p>
                                For Booking : <a href="tel:9236884854">+91-9236884854</a>
                            </p>
                            <Link
                                to={'/getyourenquiry'}
                                className="btn-get-started"
                                style={{
                                    backgroundColor: '#ff6347',
                                    color: '#fff',
                                    padding: '10px 20px',
                                    borderRadius: '5px',
                                }}
                            >
                                Get Started
                            </Link>
                        </div>
                    </div>

                    <div className="carousel-item">
                        <img
                            src="assets/img/hero-carousel/slider-2.png"
                            alt="Slide 2"
                            className="d-block w-100"
                            style={{ objectFit: 'cover' }}
                        />
                        <div
                            className="carousel-container d-flex flex-column justify-content-center align-items-center"
                            style={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                color: '#fff',
                                textAlign: 'center',
                            }}
                        >
                            <h2>Every Thursday Offer</h2>
                            <p>Hand Wax & Half Leg Wax - 299/-</p>
                            <p>Cleanup & D-Tan 499/-</p>
                            <p>Thread Work 49/-</p>
                            <p>Manicure & Pedicure - 699/-</p>
                            <p>Basic Hair Cut 99/-</p>
                        </div>
                    </div>

                    <div className="carousel-item">
                        <img
                            src="assets/img/hero-carousel/slider-3.png"
                            alt="Slide 3"
                            className="d-block w-100"
                            style={{ objectFit: 'cover' }}
                        />
                        <div
                            className="carousel-container d-flex flex-column justify-content-center align-items-center"
                            style={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                color: '#fff',
                                textAlign: 'center',
                            }}
                        >
                            <h2>Advance Booking Get Discount</h2>
                            <p>Advance Bridal Booking get 10% Discount</p>
                            <p>Advance Party Booking get 10% Discount</p>
                            <p>Bridal makeup starts at 7999</p>
                            <p>Party makeup starts at 1499</p>
                            <p>
                                Call For Booking: <a href="tel:9236884854">+91-9236884854</a>
                                <br/>
                                <a href="tel:8400081708">+91-8400081708</a>
                            </p>
                        </div>
                    </div>
                </div>

                <a className="carousel-control-prev" href="#hero-carousel" role="button" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
                </a>

                <a className="carousel-control-next" href="#hero-carousel" role="button" data-bs-slide="next">
                    <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
                </a>

                <ol className="carousel-indicators">
                    <li data-bs-target="#hero-carousel" data-bs-slide-to="0" className="active"></li>
                    <li data-bs-target="#hero-carousel" data-bs-slide-to="1"></li>
                    <li data-bs-target="#hero-carousel" data-bs-slide-to="2"></li>
                </ol>
            </div>
        </section>
    );
}

export default Carousel;
