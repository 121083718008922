import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const HomeHeader = () => {
  // State to manage the visibility of the mobile menu
  const [isMenuOpen, setMenuOpen] = useState(false);

  // Function to toggle the menu
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <header id="header" className="header d-flex align-items-center sticky-top">
      <div className="container-fluid container-xl position-relative d-flex align-items-center">
        <a href="#hero" className="logo d-flex align-items-center me-auto">
          <img src="assets/img/reboonding_logo.png" alt="" />
        </a>

        {/* Desktop Navigation */}
        <nav id="navmenu" className="navmenu d-none d-xl-block ">
          <ul>
            <li><a className="active" href="#hero">Home</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#portfolio">Portfolio</a></li>
            <li><a href="#Gallery">Gallery</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>

        {/* Hamburger Button for Mobile */}
        <i className="mobile-nav-toggle d-xl-none bi bi-list" onClick={toggleMenu}></i>

        <Link className="btn-getstarted" to={'/getyourenquiry'}>Get your enquiry
        </Link>

        {/* Mobile Menu Popup */}
        {isMenuOpen && (
          <div className="mobile-menu-overlay">
            <div className="mobile-menu">
              <button className="close-menu" onClick={toggleMenu}>×</button>
              <ul>
                <li><a href="#hero" className="active" onClick={toggleMenu}>Home</a></li>
                <li><a href="#services" onClick={toggleMenu}>Services</a></li>
                <li><a href="#portfolio" onClick={toggleMenu}>Portfolio</a></li>
                <li><a href="#Gallery" onClick={toggleMenu}>Gallery</a></li>
                <li><a href="#contact" onClick={toggleMenu}>Contact</a></li>
                <li><a href="/getyourenquiry" onClick={toggleMenu}>Get your enquiry</a></li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default HomeHeader;
