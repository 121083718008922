import React, { useState, useEffect } from 'react';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import { Link } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';  // Import Bootstrap CSS
import AOS from 'aos';
import 'aos/dist/aos.css';
import Loader from '../Component/Loader'
const SelfCheckin = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,  // Animation duration in milliseconds
      once: true,      // Animation should happen only once
    });
  }, []);
  const [emailorPhone, setemailorPhone] = useState('');
  const [resultmessage, setresultmessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    if (emailorPhone == '' || !emailorPhone) {
      alert('email or Phone are required')
    }
    try {
      setIsLoading(true)
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "emailorPhone": emailorPhone
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch("https://reboondingbackend.onrender.com/api/selfcheckin/addselfcheckin", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setresultmessage(result.message)
          console.log(result)
          setemailorPhone('')
          setIsLoading(false)
        })
        .catch((error) => {
          console.error(error)
          setIsLoading(false)
        });
    } catch (e) {
      alert(e)
      setIsLoading(false)
    }
  };

  return (
    <>
      <Header />
      <div className="container py-5" >
        <Loader
          message={`Please Wait..`}
          isLoading={isLoading}
        />
        <div style={{ backgroundColor: '#e0e0e0', borderRadius: '15px', }} className="shadow-lg p-5">
          <h2 className="text-center mb-4" data-aos="fade-up" data-aos-delay="100" style={{ fontSize: '24px', fontWeight: 'bold', color: '#333' }}>
            Self Check-In
          </h2>
          <p className="text-center mb-4" data-aos="fade-up" data-aos-delay="100" style={{ fontSize: '16px', color: '#666' }}>
            Please enter your contact number or email that you used during booking.
          </p>
          <p className="text-center mb-4" data-aos="fade-up" data-aos-delay="100" style={{ fontSize: '16px', color: '#666' }}>
            {resultmessage}
          </p>
          <div className="needs-validation" data-aos="fade-up" data-aos-delay="100">
            <div className="mb-3">
              <label htmlFor="contactInfo" className="form-label text-dark">Contact Number / Email</label>
              <input
                type="text"
                id="contactInfo"
                name="contactInfo"
                value={emailorPhone}
                onChange={(e) => { setemailorPhone(e.target.value); setresultmessage('') }}
                className="form-control"
                placeholder="Enter Contact Number or Email"
                required
              />
            </div>

            <div className="text-center">
              <button onClick={handleSubmit} className="btn btn-lg" style={{
                backgroundImage: 'linear-gradient(45deg, #ff6347, #ff4500)',
                color: '#fff',
                padding: '10px 20px',
                borderRadius: '8px',
                border: 'none',
                marginBottom: '15px'
              }}>
                Submit
              </button>
            </div>
          </div>

          <div className="text-center mt-4">
            <p style={{ color: '#333' }}>Haven't booked your appointment yet?</p>
            <Link to="/bookAppointment" className="btn btn-lg" style={{
              backgroundColor: '#333',
              color: '#fff',
              padding: '10px 20px',
              borderRadius: '8px',
              border: 'none'
            }}>
              Book Now
            </Link>
          </div>
        </div>
        <div className="position-fixed" style={{ bottom: '20px', right: '20px', zIndex: 9999999999 }}>
          <a href="https://wa.me/+918400081708" target="_blank" rel="noopener noreferrer">
            <img
              src="assets//img//WhatsApp_icon.png" // Add WhatsApp icon image path
              alt="WhatsApp"
              style={{ width: '60px', height: '60px' }}
            />
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SelfCheckin;
