import React, { useEffect, memo } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
function Services() {
    useEffect(() => {
        AOS.init({
            duration: 1000,  // Animation duration in milliseconds
            once: true,      // Animation should happen only once
        });
    }, []);
    return (
        <section id="services" className="services section" >


            <div className="container section-title" data-aos="fade-up">
                <h2>Services</h2>
                <div><span>Check Our</span> <span className="description-title">Services</span></div>
            </div>

            <div className="container mt-4" data-aos="fade-up" data-aos-delay="100">
                <div className="row">
                    <div className="col-md-4 col-sm-12 mb-4">
                        <div className="card hover-card1">
                            <div className="card-body">
                                <h5 className="card-title">BRIDAL SERVICES</h5>
                                {/* <p className="card-text">This is card 1 description.</p> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-12 mb-4">
                        <div className="card hover-card2">
                            <div className="card-body">
                                <h5 className="card-title">HAIR TREATMENT</h5>
                                {/* <p className="card-text">This is card 2 description.</p> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-12 mb-4">
                        <div className="card hover-card3">
                            <div className="card-body">
                                <h5 className="card-title">SKIN TREATMENT</h5>
                                {/* <p className="card-text">This is card 3 description.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 mb-4" data-aos="fade-up" data-aos-delay="200">
                        <div className="card hover-card4">
                            <div className="card-body">
                                <h5 className="card-title">HYDRA FACIAL</h5>
                                {/* <p className="card-text">This is card 3 description.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 mb-4" data-aos="fade-up" data-aos-delay="200">
                        <div className="card hover-card5">
                            <div className="card-body">
                                <h5 className="card-title">NAILS ART</h5>
                                {/* <p className="card-text">This is card 3 description.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 mb-4" data-aos="fade-up" data-aos-delay="200">
                        <div className="card hover-card6">
                            <div className="card-body">
                                <h5 className="card-title">MEHNDI ART</h5>
                                {/* <p className="card-text">This is card 3 description.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default memo(Services)