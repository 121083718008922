import React, { useState,useEffect } from 'react';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import { Link } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';  // Import Bootstrap CSS
import AOS from 'aos';
import 'aos/dist/aos.css';
const Memberships = () => {
    const [contactInfo, setContactInfo] = useState('');
    useEffect(() => {
        AOS.init({
          duration: 1000,  // Animation duration in milliseconds
          once: true,      // Animation should happen only once
        });
      }, []);
    const memberships = [
        {
            title: 'utakars',
            price: 'INR 1000.00',
            description: 'Become utakars member when you spend INR 0.00 or more',
            discounts: [
                '20% discount on each service',
                '0% discount on products',
                '20% discount on packages'
            ]
        },
        {
            title: 'reboonding mambership',
            price: 'INR 1200.00',
            description: 'Become reboonding membership member when you spend INR 0.00 or more',
            discounts: [
                '20% discount on each service',
                '0% discount on products',
                '20% discount on packages'
            ]
        },
        {
            title: 'reboonding family salon',
            price: 'INR 1199.00',
            description: 'Become reboonding family salon member when you spend INR 10000.00 or more',
            discounts: [
                '20% discount on each service',
                '0% discount on products',
                '0% discount on packages'
            ]
        },
        {
            title: 'GOLDEN MEMBER SHIP',
            price: 'INR 1199.00',
            description: 'Become GOLDEN MEMBER SHIP member when you spend INR 0.00 or more',
            discounts: [
                '20% discount on each service',
                '0% discount on products',
                '0% discount on packages'
            ]
        },
        
    ];

    return (
        <>
            <Header />
            <div className="container py-5">
                <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="100">
                    {memberships.map((membership, index) => (
                        <div className="col-md-3 col-lg-3 col-sm-12 mb-3" key={index}>
                            <div className="card h-100 shadow-sm" style={{ borderRadius: '15px', backgroundColor: '#fff', overflow: 'hidden' }}>
                                <div className="card-body text-center">
                                    <h5 className="" style={{ fontSize: '20px', fontWeight: 'bold',color:'#000' }}>{membership.title}</h5>
                                    <h6 className="card-subtitle mb-2 text-muted" style={{ fontSize: '24px', fontWeight: 'bold' }}>{membership.price}</h6>
                                    <hr />
                                    <p className="card-text" style={{ fontSize: '14px', color: '#666' }}>{membership.description}</p>
                                    <ul className="list-unstyled">
                                        {membership.discounts.map((discount, idx) => (
                                            <li key={idx} style={{ fontSize: '14px', color: '#333' }}>{discount}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="position-fixed" style={{ bottom: '20px', right: '20px', zIndex: 9999999999 }}>
          <a href="https://wa.me/+918400081708" target="_blank" rel="noopener noreferrer">
            <img
              src="assets//img//WhatsApp_icon.png" // Add WhatsApp icon image path
              alt="WhatsApp"
              style={{ width: '60px', height: '60px' }}
            />
          </a>
        </div>
            </div>
            <Footer />
        </>
    );
};

export default Memberships;
