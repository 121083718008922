import React, { useState,useEffect } from 'react';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import { Link } from 'react-router-dom';
// import Gallery from '../Component/gallery';
import AOS from 'aos';
import 'aos/dist/aos.css';
const ServiceProviders = () => {
    useEffect(() => {
        AOS.init({
          duration: 1000,  // Animation duration in milliseconds
          once: true,      // Animation should happen only once
        });
      }, []);
    const teamMembers = [
        { name: 'AFSAR', role: 'Hairdresser', imgUrl: 'assets/img/userICon.png' },
        { name: 'Rehan', role: 'Hair dresser', imgUrl: 'assets/img/userICon.png' },
        { name: 'Jagriti', role: 'Betuation', imgUrl: 'assets/img/userICon.png' },
        { name: 'VARSHA', role: 'FEMALE WORK', imgUrl: 'assets/img/userICon.png' },
        { name: 'Ishant rajput', role: 'Hairstylish', imgUrl: 'assets/img/userICon.png' }
    ];
    return (
        <>
            <Header />
            <div className="container py-5">
                <div className="row justify-content-center">
                    <div className="container">

                        <div className="row gy-4">



                            <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="100">
                                {teamMembers.map((member, index) => (
                                    <div className="col-md-4 col-lg-3 mb-4" key={index} >
                                        <div className="card text-center shadow-sm" style={{ borderRadius: '15px',overflow:'hidden' }}>
                                            <div className="card-body">
                                                <span className="badge bg-dark mb-3" style={{ position: 'absolute', top: '15px', left: '15px', fontSize: '12px' }}>
                                                    {member.role}
                                                </span>
                                                <img
                                                    src={member.imgUrl} // Add proper image URLs here
                                                    alt={member.name}
                                                    className="rounded-circle mb-3"
                                                    style={{ width: '80px', height: '100px', objectFit: 'cover' }}
                                                />
                                                <h5 className="card-title">{member.name}</h5>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="position-fixed" style={{ bottom: '20px', right: '20px', zIndex: 9999999999 }}>
          <a href="https://wa.me/+918400081708" target="_blank" rel="noopener noreferrer">
            <img
              src="assets//img//WhatsApp_icon.png" // Add WhatsApp icon image path
              alt="WhatsApp"
              style={{ width: '60px', height: '60px' }}
            />
          </a>
        </div>
            </div>
            <Footer />
        </>
    );
};

export default ServiceProviders;
