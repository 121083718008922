import React, { useEffect,memo } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
function Gallery() {
    useEffect(() => {
        AOS.init({
            duration: 1000,  // Animation duration in milliseconds
            once: true,      // Animation should happen only once
        });
    }, []);
    return (
        <>
            <section id="Gallery" className="Gallery section">

               
                <div className="container section-title" data-aos="fade-up">
                    <h2>Gallery</h2>
                    <div><span className="description-title">Photos Collections</span></div>
                </div>

                <div className="container">

                    <div className="row gy-4">

                        <div className="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                            <div className="member">
                                <img src="assets/img/Gallery/galleryphoto-1.png" className="img-fluid" alt=""/>
                                    {/* <div className="member-info">
                                        <div className="member-info-content">
                                            <h4>Walter White</h4>
                                            <span>Chief Executive Officer</span>
                                        </div>
                                        <div className="social">
                                            <a href=""><i className="bi bi-twitter-x"></i></a>
                                            <a href=""><i className="bi bi-facebook"></i></a>
                                            <a href=""><i className="bi bi-instagram"></i></a>
                                            <a href=""><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div> */}
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                            <div className="member">
                                <img src="assets/img/Gallery/galleryphoto-2.png" className="img-fluid" alt=""/>
                                    {/* <div className="member-info">
                                        <div className="member-info-content">
                                            <h4>Sarah Jhonson</h4>
                                            <span>Product Manager</span>
                                        </div>
                                        <div className="social">
                                            <a href=""><i className="bi bi-twitter-x"></i></a>
                                            <a href=""><i className="bi bi-facebook"></i></a>
                                            <a href=""><i className="bi bi-instagram"></i></a>
                                            <a href=""><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div> */}
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                            <div className="member">
                                <img src="assets/img/Gallery/galleryphoto-3.png" className="img-fluid" alt=""/>
                                    {/* <div className="member-info">
                                        <div className="member-info-content">
                                            <h4>William Anderson</h4>
                                            <span>CTO</span>
                                        </div>
                                        <div className="social">
                                            <a href=""><i className="bi bi-twitter-x"></i></a>
                                            <a href=""><i className="bi bi-facebook"></i></a>
                                            <a href=""><i className="bi bi-instagram"></i></a>
                                            <a href=""><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div> */}
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                            <div className="member">
                                <img src="assets/img/Gallery/galleryphoto-4.png" className="img-fluid" alt=""/>
                                    {/* <div className="member-info">
                                        <div className="member-info-content">
                                            <h4>Amanda Jepson</h4>
                                            <span>Accountant</span>
                                        </div>
                                        <div className="social">
                                            <a href=""><i className="bi bi-twitter-x"></i></a>
                                            <a href=""><i className="bi bi-facebook"></i></a>
                                            <a href=""><i className="bi bi-instagram"></i></a>
                                            <a href=""><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div> */}
                            </div>
                        </div>

                    </div>

                </div>

            </section>
        </>

    )
}

export default memo(Gallery)