import React, { useState,useEffect } from 'react';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import { Link } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';  // Import Bootstrap CSS
import AOS from 'aos';
import 'aos/dist/aos.css';
const Packages = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,  // Animation duration in milliseconds
      once: true,      // Animation should happen only once
    });
  }, []);
  const [contactInfo, setContactInfo] = useState('');

  const handleChange = (e) => {
    setContactInfo(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle check-in logic
    console.log('Checking in with:', contactInfo);
  };

  return (
    <>
      <Header />
      <div className="container py-5" data-aos="fade-up" data-aos-delay="100">
        <div style={{ backgroundColor: '#e0e0e0', borderRadius: '15px', }} className="shadow-lg p-5">
          <h2 className="text-center mb-4" style={{ fontSize: '24px', fontWeight: 'bold', color: '#333' }}>
            No package available...
          </h2>
          {/* <p className="text-center mb-4" style={{ fontSize: '16px', color: '#666' }}>
            Please enter your contact number or email that you used during booking.
          </p> */}
        </div>
        <div className="position-fixed" style={{ bottom: '20px', right: '20px', zIndex: 9999999999 }}>
          <a href="https://wa.me/+918400081708" target="_blank" rel="noopener noreferrer">
            <img
              src="assets//img//WhatsApp_icon.png" // Add WhatsApp icon image path
              alt="WhatsApp"
              style={{ width: '60px', height: '60px' }}
            />
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Packages;
