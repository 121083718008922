import { useState } from "react";

const Portfolio = () => {
    const [activeFilter, setActiveFilter] = useState("*");

    // List of portfolio items (data source can be replaced with actual data)
    const portfolioItems = [
        { id: 1, category: "filter-mackup", title: "Mackup ", imgSrc: "assets/img/portfolio/mackup1.png", description: "Lorem ipsum, dolor sit amet consectetur" },
        { id: 2, category: "filter-mackup", title: "Mackup 2", imgSrc: "assets/img/portfolio/mackup2.png", description: "Lorem ipsum, dolor sit amet consectetur" },
        { id: 3, category: "filter-mehndi", title: "Mehndi ", imgSrc: "assets/img/portfolio/mehndi1.png", description: "Lorem ipsum, dolor sit amet consectetur" },
        { id: 4, category: "filter-mehndi", title: "Mehndi 2", imgSrc: "assets/img/portfolio/mehndi2.png", description: "Lorem ipsum, dolor sit amet consectetur" },
        { id: 7, category: "filter-haircut", title: "Haircut ", imgSrc: "assets/img/portfolio/haircut1.png", description: "Lorem ipsum, dolor sit amet consectetur" },
        { id: 5, category: "filter-haircut", title: "Haircut 2", imgSrc: "assets/img/portfolio/haircut2.png", description: "Lorem ipsum, dolor sit amet consectetur" },
        { id: 6, category: "filter-photoshoot", title: "Photo Shoot", imgSrc: "assets/img/portfolio/photoshoot1.png", description: "Lorem ipsum, dolor sit amet consectetur" },
        { id: 8, category: "filter-photoshoot", title: "photoshoot 2", imgSrc: "assets/img/portfolio/mackup2.png", description: "Lorem ipsum, dolor sit amet consectetur" },
        // Add more items as needed
    ];

    // Function to handle filter click
    const handleFilterClick = (filter) => {
        setActiveFilter(filter);
    };

    return (
        <section id="portfolio" className="portfolio section">
            <div className="container section-title" data-aos="fade-up">
                <h2>Portfolio</h2>
                <div><span>Check Our</span> <span className="description-title">Portfolio</span></div>
            </div>

            <div className="container">
                <div className="isotope-layout" data-layout="masonry" data-sort="original-order">
                    {/* Portfolio Filters */}
                    <ul className="portfolio-filters isotope-filters" data-aos="fade-up" data-aos-delay="100">
                        <li
                            className={activeFilter === "*" ? "filter-active" : ""}
                            onClick={() => handleFilterClick("*")}
                        >All</li>
                        <li
                            className={activeFilter === "filter-mackup" ? "filter-active" : ""}
                            onClick={() => handleFilterClick("filter-mackup")}
                        >Mackup</li>
                        <li
                            className={activeFilter === "filter-mehndi" ? "filter-active" : ""}
                            onClick={() => handleFilterClick("filter-mehndi")}
                        >Mehndi</li>
                        <li
                            className={activeFilter === "filter-haircut" ? "filter-active" : ""}
                            onClick={() => handleFilterClick("filter-haircut")}
                        >Haircut</li>
                        <li
                            className={activeFilter === "filter-photoshoot" ? "filter-active" : ""}
                            onClick={() => handleFilterClick("filter-photoshoot")}
                        >photoshoot</li>
                    </ul>

                    {/* Portfolio Items */}
                    <div className="row gy-4 isotope-container" data-aos="fade-up" data-aos-delay="200">
                        {portfolioItems
                            .filter(item => activeFilter === "*" || item.category === activeFilter)
                            .map(item => (
                                <div key={item.id} className={`col-lg-4 col-md-6 portfolio-item isotope-item ${item.category}`}>
                                    <div className="portfolio-content h-100">
                                        <a href={item.imgSrc} data-gallery="portfolio-gallery-app" className="glightbox">
                                            <img src={item.imgSrc} className="img-fluid" alt="" />
                                        </a>
                                        <div className="portfolio-info">
                                            <h4><a href="portfolio-details.html" title="More Details">{item.title}</a></h4>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Portfolio;
