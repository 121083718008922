import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Faq() {
    useEffect(() => {
        AOS.init({
            duration: 1000,  // Animation duration in milliseconds
            once: true,      // Animation should happen only once
        });
    }, []);

    return (
        <>
            <section id="faq" className="faq section">

                <div className="container section-title" data-aos="fade-up">
                    <h2>Frequently Asked Questions</h2>
                    <div> 
                        <span className="description-title">Frequently Asked Questions</span>
                    </div>
                </div>

                <div className="container">

                    <div className="row faq-item" data-aos="fade-up" data-aos-delay="100">
                        <div className="col-lg-5 d-flex">
                            <i className="bi bi-question-circle"></i>
                            <h4>What services does Reboonding Family Salon offer?</h4>
                        </div>
                        <div className="col-lg-7">
                            <p>
                                Reboonding Family Salon offers a wide range of beauty and grooming services, including haircuts, styling, waxing, facials, bridal makeup, party makeup, manicure, pedicure, and much more.
                            </p>
                        </div>
                    </div>

                    <div className="row faq-item" data-aos="fade-up" data-aos-delay="200">
                        <div className="col-lg-5 d-flex">
                            <i className="bi bi-question-circle"></i>
                            <h4>Do you offer any discounts or special offers?</h4>
                        </div>
                        <div className="col-lg-7">
                            <p>
                                Yes, we offer special discounts on advance bridal and party bookings. You can enjoy 10% off on bridal makeup packages and party bookings. We also have exclusive Thursday offers for services like waxing, facials, and haircuts.
                            </p>
                        </div>
                    </div>

                    <div className="row faq-item" data-aos="fade-up" data-aos-delay="300">
                        <div className="col-lg-5 d-flex">
                            <i className="bi bi-question-circle"></i>
                            <h4>How can I make an appointment?</h4>
                        </div>
                        <div className="col-lg-7">
                            <p>
                                You can book an appointment by calling us directly at <a href="tel:9236884854">+91-9236884854</a>. We also accept walk-ins, but we recommend booking in advance to secure your preferred time slot.
                            </p>
                        </div>
                    </div>

                    <div className="row faq-item" data-aos="fade-up" data-aos-delay="400">
                        <div className="col-lg-5 d-flex">
                            <i className="bi bi-question-circle"></i>
                            <h4>What are your operating hours?</h4>
                        </div>
                        <div className="col-lg-7">
                            <p>
                                Reboonding Family Salon is open every day from 10:00 AM to 09:00 PM. However, for special occasions or advance bookings, we can adjust our timings to accommodate your needs.
                            </p>
                        </div>
                    </div>

                    {/* <div className="row faq-item" data-aos="fade-up" data-aos-delay="500">
                        <div className="col-lg-5 d-flex">
                            <i className="bi bi-question-circle"></i>
                            <h4>Do you offer home service for makeup and beauty treatments?</h4>
                        </div>
                        <div className="col-lg-7">
                            <p>
                                Yes, we provide home services for bridal and party makeup. Our team can come to your location to provide these services. Please contact us in advance for scheduling and availability.
                            </p>
                        </div>
                    </div> */}

                </div>

            </section>
        </>
    )
}

export default Faq;
