import React from 'react';
import '../src/App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Welcome from '../src/Screen/Home.js'
import Header from './Component/Header'
import Footer from './Component/Footer.js'
import Carousel from './Component/Carousel'
import About from './Component/About.js';
import Services from './Component/Services.js';
import Testimonials from './Component/Testimonials.js';
import Portfolio from './Component/Portfolio.js';
import Pricing from './Component/Pricing.js';
import FAQ from './Component/FAQ.js';
import Contact from './Component/Contact.js';
import Getyourenquiry from './Screen/Getyourenquiry.js';
import Enquiry from './Screen/EnquiryForm.js';
import BookAppointment from './Screen/BookAppointment.js';
import SelfCheckin from './Screen/SelfCheckin.js';
import Packages from './Screen/Packages.js';
import Memberships from './Screen/Memberships.js';
import Offers from './Screen/Offers.js';
import GalleryPage from './Screen/GalleryPage.js';
import ServiceProviders from './Screen/ServiceProviders.js';
import Reviews from './Screen/Reviews.js';
import Feedback from './Screen/Feedback.js';
const HomePage = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Welcome />} />
          <Route exact path="/getyourenquiry" element={<Getyourenquiry />} />
          <Route exact path="/enquiry" element={<Enquiry />} />
          <Route exact path="/bookAppointment" element={<BookAppointment />} />
          <Route exact path="/selfCheckin" element={<SelfCheckin />} />
          <Route exact path="/packages" element={<Packages />} />
          <Route exact path="/memberships" element={<Memberships />} />
          <Route exact path="/offers" element={<Offers />} />
          <Route exact path="/galleryPage" element={<GalleryPage />} />
          <Route exact path="/serviceproviders" element={<ServiceProviders />} />
          <Route exact path="/reviews" element={<Reviews />} />
          <Route exact path="/feedback" element={<Feedback />} />
        </Routes>
      </Router>
    </div>
  );
};

export default HomePage;
