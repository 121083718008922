import React from 'react';
import HomeHeader from '../Component/HomeHeader.js'
import HomeFooter from '../Component/HomeFooter.js'
import Carousel from '../Component/Carousel'
import About from '../Component/About.js';
import Services from '../Component/Services.js';
import Testimonials from '../Component/Testimonials.js';
import Portfolio from '../Component/Portfolio.js';
import Gallery from '../Component/gallery.js';
import Pricing from '../Component/Pricing.js';
import FAQ from '../Component/FAQ.js';
import Contact from '../Component/Contact.js';
const HomePage = () => {
    return (
        <div>
            <HomeHeader />
            <Carousel />
            <About />
            <Services />
            {/* <Testimonials /> */}
            <Portfolio />
            <Gallery />
            {/* <Pricing /> */}
            <FAQ />
            <Contact />
            <HomeFooter />

        </div>
    );
};

export default HomePage;
