import React, { useState,useEffect } from 'react';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
const Offers = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,  // Animation duration in milliseconds
      once: true,      // Animation should happen only once
    });
  }, []);
  return (
    <>
      <Header />
      <div className="container py-5" data-aos="fade-up" data-aos-delay="100">
        <div className="row justify-content-center">
          <img style={{width:250,height:250}} src='assets\img\offers\offers1.png' alt='' />
        </div>
        <div className="position-fixed" style={{ bottom: '20px', right: '20px', zIndex: 9999999999 }}>
          <a href="https://wa.me/+918400081708" target="_blank" rel="noopener noreferrer">
            <img
              src="assets//img//WhatsApp_icon.png" // Add WhatsApp icon image path
              alt="WhatsApp"
              style={{ width: '60px', height: '60px' }}
            />
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Offers;
